import React from "react";
// import blog3Data from "data/blog3.json";
import DarkTheme from "layouts/Dark";
import Navbar from "components/Navbar/navbar";

import PageHeader from "components/Page-header/page-header";
import Footer from "components/Footer/footer";
import CustomContactForm from "components/Contact-form/custom-contact-form";

const config = {
  "templateId":"template_elf_sixth_sense",
  "formType":"corporate",
  "emailMsg":"Lead For Corporate Training:Transform Your Organization with Our Comprehensive Training Programs!",
}

const BlogDetailsDark = () => {

 
  const navbarRef = React.useRef(null);
  const logoRef = React.useRef(null);

  React.useEffect(() => {
    var navbar = navbarRef.current;

    if (window.pageYOffset > 300) {
      navbar.classList.add("nav-scroll");
    } else {
      navbar.classList.remove("nav-scroll");
    }

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        navbar.classList.add("nav-scroll");
      } else {
        navbar.classList.remove("nav-scroll");
      }
    });
  }, [navbarRef]);

  return (
    <DarkTheme>
      <div className="circle-bg">
        <div className="circle-color fixed">
          <div className="gradient-circle"></div>
          <div className="gradient-circle two"></div>
        </div>
      </div>
      <Navbar nr={navbarRef} lr={logoRef} />
      <PageHeader
        title="Transform Your Organization with Our Comprehensive Training Programs!"
        paragraph="Empower. Innovate. Succeed."
      />
       <section className="blog-pg single section-padding pt-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="post">
                  <div className="img">
                    <img src="/img/blogs/Blog_8/CT-01.jpg" alt="Transform Your Organization with Our Comprehensive Training Programs." />
                  </div>
                  <div className="content pt-60">
                    <div className="row justify-content-center">
                      <div className="col-lg-10">
                        <div className="cont blog_h2">
                          <p>
                            In today’s fast-paced and ever-evolving business landscape, staying ahead of the competition requires a dedicated focus on continuous learning and development. Investing in corporate training is one of the most effective ways to ensure your team remains skilled, motivated and ready to tackle any challenge. Our comprehensive corporate training solutions are designed to empower your employees, drive innovation and elevate your organization to new heights.
                          </p>
                          
                          <p>
                            Our mission is to provide top-tier training programs that align with your business goals and help your team reach their full potential. We understand that each organization has unique needs, which is why our training solutions are customized to fit your specific requirements. Whether you're looking to enhance leadership skills, improve technical expertise or foster better teamwork, our expert-led programs have you covered.
                          </p>
                          <br/>
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_8/CT-04.jpg" alt="Transform Your Organization with Our Comprehensive Training Programs!" />
                              </div>
                            </div>
                          </div>
                          <br/>
                          <h2> - Why Partner with Us?  </h2>
                          
                          <p>
                            Choosing the right training partner is crucial for the success of your organization. Here’s why we stand out :
                          </p>

                          <p>
                            <strong className="text-white">Customized Learning Paths : </strong>

                            Our training programs are tailored to meet the unique needs of your business. We work closely with you to develop learning paths that align with your objectives and address your team’s specific challenges.
                          </p>


                          <p>
                            <strong className="text-white">Flexible Delivery Options : </strong>

                            We offer a variety of delivery methods to suit your organization’s preferences, including in-person workshops, virtual classrooms and self-paced online courses. This flexibility allows your team to learn at their own pace and convenience.
                          </p>

                          <p>
                            <strong className="text-white">Proven Results : </strong>

                            Our training programs are designed to deliver measurable outcomes. We use robust assessment tools and feedback mechanisms to track progress and ensure your team is benefiting from the training. With our solutions, you can expect to see a tangible improvement in performance and productivity.
                          </p>
                          <br/>
                          <div className="row justify-content-center">
                            <div className="col-md-6">
                              <div className="mb-10">
                                <img src="/img/blogs/Blog_8/CT-02.jpg" alt="Transform Your Organization with Our Comprehensive Training Programs!" />
                              </div>
                            </div>
                          </div>
                          <br/>
                          <h2>
                           - Experience the Future of Corporate Training
                          </h2>

                          <p>
                            The future of work demands a workforce that is not only skilled but also adaptable and innovative. By investing in our corporate training programs, you are equipping your team with the tools they need to thrive in a dynamic environment. Our training solutions are more than just educational sessions—they are transformative experiences that inspire and empower your employees to excel.
                          </p>

                          <p>
                            Don’t let your organization fall behind. Embrace the power of professional corporate training and unlock your team’s potential. Our comprehensive programs cover a wide range of topics, including leadership development, technical skills, communication, project management and more.
                          </p>

                          <h2>
                            Get Started Today!
                          </h2>

                          <p>
                            Are you ready to take your organization to the next level? Contact us today to learn more about our corporate training solutions and how we can help your team succeed. Together, we can create a culture of continuous learning and drive your business forward.
                          </p>

                          <p>
                            Elevate your team, innovate your processes and succeed in your industry with our expert-led corporate training solutions. Let’s build a brighter future together.
                          </p>

                          <div className="line bottom left w-100"></div>
                          <div className="share-inf">
                            {/* <div className="social">
                              <a href="https://www.linkedin.com/company/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                              </a>
                              <a href="https://www.instagram.com/elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-instagram"></i>
                              </a>
                              <a href="https://www.youtube.com/@ElfonzeTechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                              </a>
                              <a href="https://www.facebook.com/Elfonzetechnologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                              <a href=" https://t.me/elfonze_technologies" target="_blank">
                                <i className="fab fa-telegram"></i>
                              </a>
                              <a href="https://www.quora.com/profile/Elfonze-Technologies" target="_blank" rel="noreferrer">
                                <i className="fab fa-quora"></i>
                              </a>
                              <a href="https://twitter.com/Elfonze_Tech" target="_blank" rel="noreferrer">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </div> */}
                            <div className="tags">
                              {/* <a href="#0">Web</a>,<a href="#0">Themeforest</a>,
                              <a href="#0">ThemesCamp</a> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        <div className="container">
          <CustomContactForm config={config} />
        </div>

        </section>

      <Footer />
    </DarkTheme>
  );
};

export const Head = () => {
  return (
    <>
      <title>Transform Your Organization with Our Comprehensive Training Programs.</title>
      <meta key="description" name="description" 
        content="In today’s fast-paced and ever-evolving business landscape, staying ahead of the competition requires a dedicated focus on continuous learning and development. Investing in corporate training is one of the most effective ways to ensure your team remains skilled, motivated and ready to tackle any challenge. Our comprehensive corporate training solutions are designed to empower your employees, drive innovation and elevate your organization to new heights." />
    </>
  )
}

export default BlogDetailsDark;
